.loading:after {
  content: '\00a0\00a0\00a0';
  animation: progress-ellipsis 2s infinite;
}

@keyframes progress-ellipsis {
  0% {
      content: '\00a0\00a0\00a0';
  }
  30% {
      content: '.\00a0\00a0';
  }
  60% {
      content: '..\00a0';
  }
  90% {
      content: '...';
  }
}